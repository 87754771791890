<template>
    <div>
        <br>
        <br>
        <div class="col d-sm-flex justify-content-center">
            <div class="card">
                <div v-if="!privacyAccepted">
                    <p>È stata richiesta la tua iscrizione al nostro portale, prima di continuare devi accettare le condizioni sulla privacy.</p>
                    <b-form-checkbox
                        id="privacy"
                        v-model="privacy"
                        name="privacy">
                        Dichiaro di aver letto e accettato l'<a :href="privacyUrl" target="_blank">informativa sul trattamento dei dati</a>.
                    </b-form-checkbox>
                    <p><span v-if="errorMsg" class="text-danger">{{ errorMsg }}</span>&nbsp;</p>
                    <b-button @click="confirmProfile" variant="success">Accetta e continua</b-button>
                </div>

                <div v-if="confirmed" class="text-center">
                    <h2>Grazie per aver accettato la richiesta di inserimento nella scuola</h2>
                    <p>Ora dopo il login potrai scegliere in quale scuola operare</p>
                    <router-link class="btn btn-primary" to="/login">Vai al login</router-link>
                </div>

            </div>
        </div>
    </div>
</template>


<script>
import ProfileDataService from "@/components/profile/ProfileDataService";

export default {
    name: "confirm-profile-school",
    data() {
        return {
            confirmed: false,
            privacy: null,
            errorMsg: null,
            privacyUrl: 'https://www.toccafondimultimedia.com/privacy/',
            privacyAccepted: false,
            profile: null
        };
    },
    props: ["token"],
    methods: {
        confirmProfileSchool() {
            ProfileDataService.confirmProfileSchool(this.token)
                .then(response => {
                    this.confirmed = true
                })
                .catch(err => {
                    console.log(err)
                })
        },
        confirmProfile() {
            if (!this.privacy) {
                this.errorMsg = 'È necessario accettare le condizioni della privacy per poter procedere.';
            } else ProfileDataService.confirmProfile(this.token)
                .then(response => {
                    this.confirmed = true
                    this.privacyAccepted = true
                    this.errorMsg = null;
                    this.confirmProfileSchool();
                })
                .catch(err => {
                    console.log(err)
                })
        }
    },
    mounted() {
        if (this.$store.state.isLoggedIn) {
            localStorage.removeItem('authUser');
            this.$store.state.isLoggedIn = false;
            this.$store.state.user = null;
            this.$store.state.token = null;
        }
        // check if privacy was accepted
        let profile = null;
        ProfileDataService.getByConfirmToken(this.token)
            .then(response => {
                this.profile = response.data
                this.privacyAccepted = (this.profile.privacyAcceptanceDate != null);
                if (this.privacyAccepted) {
                    // if true launch the confirmProfileSchool() instantly
                    this.confirmProfileSchool();
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
};
</script>

<style scoped>

</style>
